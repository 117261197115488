import React, { useState } from 'react'
import OfficerTargetAchivementCard from './OfficerTargetAchivementCard'
import { Bar } from 'react-chartjs-2';
import { data, options } from '../../../../../Helper/Data';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function OfficerTargetAchivement({ Countdata, LableName, activeFilter, RoleID }) {

    const navigate = useNavigate();

    const targetOrderNavigate = (name, screenFlag) => {
        if (screenFlag === "Target" && name === "State In-charge (Nos)") {
            navigate(`/targetorder?name=${name}&ApiFlag=StateIncharge&activeFilter=${activeFilter}&DDLFlag=DB_OrderTarget&OfficerNameDDLFlag=DB_OrderTargetStateIncharge`) // {state: screenFlag}
        }
        // else if (screenFlag === "Target" && name === "Regional Manager (Nos)") {
        //     navigate(`/targetorder?name=${name}&ApiFlag=RegionalManager&activeFilter=${activeFilter}&DDLFlag=DB_OrderTarget&OfficerNameDDLFlag=DB_OrderTargetRegionalManager`)
        // } 
        else if (screenFlag === "Target" && name === "Regional Manager (Nos)") {
            navigate(`/targetorder?name=${name}&ApiFlag=DisctrictOfficer&activeFilter=${activeFilter}&DDLFlag=DB_OrderTarget&OfficerNameDDLFlag=DB_OrderTargetDisctrictOfficer`)
        }
        // else if (screenFlag === "Target" && name === "Sales Trainee (Nos)") {
        //     navigate(`/targetorder?name=${name}&ApiFlag=SalesTrainee&activeFilter=${activeFilter}&DDLFlag=DB_OrderTarget&OfficerNameDDLFlag=DB_OrderTargetSalesTrainee&ProductNameDDLFlag=DB_SAOrderTarget`)
        // }
        else if (screenFlag === "Target" && name === "Sales Executive (Nos)") {
            navigate(`/targetorder?name=${name}&ApiFlag=FieldAssistant&activeFilter=${activeFilter}&DDLFlag=DB_FAOrderTarget&StateDDLFlag=DB_OrderTarget &OfficerNameDDLFlag=DB_FAOrderTarget&ProductNameDDLFlag=DB_FAOrderTarget`)
        } else if (screenFlag === "Order Achievement" && name === "State In-charge (Nos)") {
            navigate(`/achievedorder?name=${name}&screenFlag=${screenFlag}&ApiFlag=StateIncharge&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&OfficerNameDDLFlag=DB_OrderAchivementStateIncharge&ExtraAchievedGridFlag=StateIncharge_Extra&ExtraAchievedDDLFlag=DB_ExtraAchivement&ExtraAcheivedOfficerNameDDLFlag=DB_ExtraAchivement_State`)
        }
        // else if (screenFlag === "Order Achievement" && name === "Regional Manager (Nos)") {
        //     navigate(`/achievedorder?name=${name}&screenFlag=${screenFlag}&ApiFlag=RegionalManager&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&OfficerNameDDLFlag=DB_OrderAchivementRegionalManager&ExtraAchievedGridFlag=RegionalManager_Extra&ExtraAchievedDDLFlag=DB_ExtraAchivement&ExtraAcheivedOfficerNameDDLFlag=DB_ExtraAchivement_RM`)
        // } 
        else if (screenFlag === "Order Achievement" && name === "Regional Manager (Nos)") {
            navigate(`/achievedorder?name=${name}&screenFlag=${screenFlag}&ApiFlag=DisctrictOfficer&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&OfficerNameDDLFlag=DB_OrderAchivementDisctrictOfficer&ExtraAchievedGridFlag=DisctrictOfficer_Extra&ExtraAchievedDDLFlag=DB_ExtraAchivement&ExtraAcheivedOfficerNameDDLFlag=DB_ExtraAchivement_DO`)
        }
        //  else if (screenFlag === "Order Achievement" && name === "Sales Trainee (Nos)") {
        //     navigate(`/achievedorder?name=${name}&screenFlag=${screenFlag}&ApiFlag=SalesTrainee&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&OfficerNameDDLFlag=DB_OrderAchivementSalesTrainee&ExtraAchievedGridFlag=SalesTrainee_Extra&ExtraAchievedDDLFlag=DB_ExtraAchivement&ExtraAcheivedCropProductName=DB_ExtraAchieved_SA&AcheivedDDLFlag=DB_AchievedOrder_SA&ExtraAcheivedOfficerNameDDLFlag=DB_ExtraAchivement_SA`)
        // }
        else if (screenFlag === "Order Achievement" && name === "Sales Executive (Nos)") {
            navigate(`/achievedorder?name=${name}&screenFlag=${screenFlag}&ApiFlag=FieldAssistant&activeFilter=${activeFilter}&DDLFlag=DB_AchievedOrder&OfficerNameDDLFlag=DB_OrderAchivementFieldAssistant&ExtraAchievedGridFlag=FieldAssistant_Extra&ExtraAchievedDDLFlag=DB_ExtraAchivement&ExtraAcheivedCropProductName=DB_ExtraAchieved_FA&AcheivedDDLFlag=DB_AchievedOrder_FA&ExtraAcheivedOfficerNameDDLFlag=DB_ExtraAchivement_FA`)
        }
    }
    const data = {
        labels: ['State In Charge', 'Regional Manager', 'Sales Executive',],
        datasets: [
            {
                label: 'Target Order',
                data: [Countdata && Countdata.TotalTarget_StateIncharge, Countdata && Countdata.TotalTarget_DisctrictOfficer, Countdata && Countdata.TotalTarget_FieldAssistant],
                backgroundColor: [
                    '#7bc0f7', ' #7bc0f7', ' #7bc0f7', ' #7bc0f7', ' #7bc0f7'
                ],
            },
            {
                label: 'Achieved Order',
                data: [Countdata && Countdata.TotalAchived_StateIncharge, Countdata && Countdata.TotalAchived_DisctrictOfficer, Countdata && Countdata.TotalAchived_FieldAssistant],
                backgroundColor: [
                    '#f18226', '#f18226', '#f18226', '#f18226', '#f18226'
                ],
            },
        ],
    };

    const data7 = {
        labels: ['Regional Manager', 'Sales Executive',],
        datasets: [
            {
                label: 'Target Order',
                data: [ Countdata && Countdata.TotalTarget_DisctrictOfficer, Countdata && Countdata.TotalTarget_FieldAssistant],
                backgroundColor: [
                    '#3b6deb', '#3b6deb', '#3b6deb', '#3b6deb'
                ],
            },
            {
                label: 'Achieved Order',
                data: [ Countdata && Countdata.TotalAchived_DisctrictOfficer, Countdata && Countdata.TotalAchived_FieldAssistant],
                backgroundColor: [
                    '#28c8c1', '#28c8c1', '#28c8c1', '#28c8c1'
                ],
            },
        ],
    };

    const data8 = {
        labels: ['Regional Manager', 'Sales Executive',],
        datasets: [
            {
                label: 'Target Order',
                data: [Countdata && Countdata.TotalTarget_DisctrictOfficer, Countdata && Countdata.TotalTarget_FieldAssistant],
                backgroundColor: [
                    '#3b6deb', '#3b6deb', '#3b6deb', '#3b6deb'
                ],
            },
            {
                label: 'Achieved Order',
                data: [Countdata && Countdata.TotalAchived_DisctrictOfficer, Countdata && Countdata.TotalAchived_FieldAssistant],
                backgroundColor: [
                    '#28c8c1', '#28c8c1', '#28c8c1', '#28c8c1'
                ],
            },
        ],
    };


    // -----------------------Extra Acheived Graph-----------------

    const ExtraAcheiveddata = {
        labels: ['State In Charge', 'Regional Manager', 'District Officer', 'Sales Traniee', 'Field Assistant'],
        datasets: [
            {
                label: 'ExtraAchieved Order',
                data: [Countdata && Countdata.TotalAchived_StateIncharge, Countdata && Countdata.TotalAchived_RegionalManager, Countdata && Countdata.TotalAchived_DisctrictOfficer, Countdata && Countdata.TotalAchived_SalesTrainee, Countdata && Countdata.TotalAchived_FieldAssistant],
                backgroundColor: [
                    '#ffbb38', '#ffbb38', '#ffbb38', '#ffbb38', '#ffbb38'
                ],
            },
        ],
    };

    return (
        <>
            <div className="col-12 col-md-10 ota_col col-lg-7 mb-lg-0">
                <div className="card z-index-2 h-100 pb-5 card_big3" >

                    <div className="card-header pb-0 pt-3 bg-transparent">
                        <div className="row">
                            <div className="col-12 col-lg-12">
                                <h6 className="text-capitalize material-stock-heading">Officer Target & Achievement (Nos)
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 col-lg-6  py-3 ">
                            <h6 style={{ color: "#000000d1", fontWeight: "600" }}> Target Order</h6>

                            <div className="row">


                                {
                                    RoleID == 7 &&
                                    <>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_RegionalManager : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Trainee (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Executive (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div>
                                    </>

                                }

                                {
                                    RoleID == 2 || RoleID == 6 ?
                                        <>
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_StateIncharge : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="State In-charge (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Target"
                                                />
                                            </div>
                                            {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_RegionalManager : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Regional Manager (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Target"
                                                />
                                            </div> */}
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Regional Manager (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Target"
                                                />
                                            </div>
                                            {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Sales Trainee (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Target"
                                                />
                                            </div> */}
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Sales Executive (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Target"
                                                />
                                            </div>
                                        </>
                                        :
                                        ''
                                }
                                {
                                    RoleID == 8 &&
                                    <>
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Trainee (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalTarget_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Executive (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Target"
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                        <div className="col-12 col-lg-6  py-3">
                            <h6 style={{ color: "#000000d1", fontWeight: "600" }}> Achieved Order</h6>

                            <div className="row">
                                {
                                    RoleID == 7 &&
                                    <>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_RegionalManager : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Trainee (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Executive (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    RoleID == 2 || RoleID == 6 ?
                                        <>
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor ">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_StateIncharge : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="State In-charge (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Order Achievement"
                                                />
                                            </div>
                                            {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_RegionalManager : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Regional Manager (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Order Achievement"
                                                />
                                            </div> */}
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Regional Manager (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Order Achievement"
                                                />
                                            </div>
                                            {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Sales Trainee (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Order Achievement"
                                                />
                                            </div> */}
                                            <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                                <OfficerTargetAchivementCard
                                                    count={
                                                        activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                            activeFilter === "Weekly" ? "0" : "0"
                                                    }
                                                    name="Sales Executive (Nos)"
                                                    targetOrderNavigate={targetOrderNavigate}
                                                    screenFlag="Order Achievement"
                                                />
                                            </div>
                                        </>
                                        : ''
                                }

                                {
                                    RoleID == 8 &&
                                    <>
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_DisctrictOfficer : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Regional Manager (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div>
                                        {/* <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_SalesTrainee : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Trainee (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div> */}
                                        <div className="col-xl-6 col-sm-6 mt-3 cursor">
                                            <OfficerTargetAchivementCard
                                                count={
                                                    activeFilter === "Yearly" ? Countdata ? Countdata.TotalAchived_FieldAssistant : '0' : activeFilter === "Monthly" ? "0" :
                                                        activeFilter === "Weekly" ? "0" : "0"
                                                }
                                                name="Sales Executive (Nos)"
                                                targetOrderNavigate={targetOrderNavigate}
                                                screenFlag="Order Achievement"
                                            />
                                        </div>
                                    </>
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="col-lg-5 pt-5 col-md-10 chart_all card_big3 borders mt_10" >
                <div className="mt-4">
                    <div >
                        <p className="fw-bold pb-3">Officer Target & Achievement</p>
                        <Bar options={options}
                            data={
                                RoleID == 7 ?
                                    data7
                                    : RoleID == 8 ?
                                        data8
                                        :
                                        data
                            } />
                        {/* <Bar options={options}
                            data={ExtraAcheiveddata} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}


